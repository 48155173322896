<template>
    <div class="mb-4" v-loading="$waiting.is('loading.vouchers.*')">
        <div class="flex justify-between">
            <el-form class="flex space-x-2" :model="formData" :rules="rules">
                <el-form-item label="">
                    <el-input v-model="formData.series" disabled />
                </el-form-item>
                <el-form-item label="" prop="date">
                    <el-input v-model="formData.date" disabled />
                </el-form-item>
                <el-form-item label="" prop="text">
                    <el-input v-model="formData.text" disabled />
                </el-form-item>
            </el-form>
        </div>
        <div class="flex bg-white sticky top-[102px] z-0">
            <p class="font-semibold rounded w-[350px]">Konto</p>
            <p class="font-semibold rounded w-[100px] text-right">Debet</p>
            <p class="font-semibold rounded w-[100px] text-right mr-5">Kredit</p>
            <p class="font-semibold rounded flex-1">Text</p>
        </div>
        <div v-for="item in itemsData" :key="item.transactionId" class="flex text-sm">
            <p class="w-[45px]">{{ item.account }}</p>
            <p class="w-[305px]">{{ item.accountName }}</p>
            <p class="w-[100px] text-right">
                {{ item.debit | swedishNumberFormat }}
            </p>
            <p class="w-[100px] text-right mr-2">
                {{ item.credit | swedishNumberFormat }}
            </p>
            <p class="w-[18px]">
                <span v-if="item.correction" class="flex items-center justify-center bg-company-blue w-4 h-4 mt-[2px] text-xs rounded-full text-white">K</span>
            </p>
            <p class="flex-1">
                {{ item.text }}
            </p>
        </div>
        <!-- <VouchersViewTable :items-data="itemsData" :accounts-data="accountsData" class="print:hidden" /> -->
    </div>
</template>

<script>
import Api from "../../../pages/lopande/lopande.api";

export default {
    components: {
        // VouchersViewTable: () => import(/* webpackChunkName: "VouchersViewTable" */ "@/components/vouchersTable/v1/VouchersViewTable.vue"),
    },
    data() {
        return {
            seriesData: [],
            itemsData: [],
            formData: {
                series: "",
                date: "",
                text: "",
            },
            rules: {
                series: [{ required: true, message: "Required", trigger: "blur" }],
                date: [{ required: true, message: "Required", trigger: "blur" }],
                comment: [{ required: true, message: "Required", trigger: "blur" }],
            },
        };
    },
    props: {
        clientId: {
            type: String,
            default: "",
        },
        voucherId: {
            type: Number,
            default: null,
        },
    },
    async created() {
        this.getVoucherDetails();
        this.getAccounts();
        this.getSeries();
    },
    methods: {
        async getVoucherDetails() {
            this.$waiting.start("loading.vouchers.details");
            this.itemsData = [];
            let data = await Api.getVoucherDetails({ clientId: this.clientId, voucherId: this.voucherId });
            this.formData.series = data.number;
            this.formData.date = data.date;
            this.formData.text = data.text;
            this.itemsData = data.transactions;
            this.$waiting.end("loading.vouchers.details");
        },
        async getSeries() {
            this.$waiting.start("loading.vouchers.series");
            this.seriesData = await Api.getSeries();
            this.$waiting.end("loading.vouchers.series");
        },
        async getAccounts() {
            this.$waiting.start("loading.vouchers.accounts");
            this.accountsData = await Api.getAccounts(this.clientId);

            this.itemsData.forEach(item => {
                const account = this.accountsData.find(account => account.number === item.account);

                if (account || !item.readOnly) return;

                this.accountsData.push({
                    number: item.account,
                    name: item.accountName,
                });
            });
            this.$waiting.end("loading.vouchers.accounts");
        },
    },
};
</script>
